import $ from "jquery"
import sr from "./ScrollReveal"
import ScrollReveal from "scrollreveal"
import "./styles/styles.scss"

$(function() {

  // const hand = document.querySelector('.emoji.wave-hand');

  // function waveOnLoad() {
  //   hand.classList.add('wave');
  //   setTimeout(function() {
  //     hand.classList.remove('wave');
  //   }, 2000);
  // }

  // setTimeout(function() {
  //   waveOnLoad();
  // }, 2000);

  // hand.addEventListener('mouseover', function() {
  //   hand.classList.add('wave');
  // });

  // hand.addEventListener('mouseout', function() {
  //   hand.classList.remove('wave');
  // });

  window.sr = ScrollReveal({
    reset: false,
    duration: 600,
    easing: 'cubic-bezier(.694,0,.335,1)',
    scale: 1,
    viewFactor: 0.3,
  });

  sr.reveal('.home');
  sr.reveal('.about', {viewFactor: 0.2});
  sr.reveal('.experience', { viewFactor: 0.2 });
  sr.reveal('.projects', { viewFactor: 0.1 });
  sr.reveal('.other-projects', { viewFactor: 0.05 });
  sr.reveal('.whats-next', { viewFactor: 0.05 });
});
