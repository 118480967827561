import * as React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
const { Header, Content, Footer } = Layout;
import "./skills.scss";

const SkillsPage = () => {
  return (
    <Layout id="about" className="layout about">
      <div className="header">about.</div>
      <div style={{ margin: "auto 0" }}>
        <div style={{ padding: "50px 40px", fontSize: "1rem" }}>
          <p>
            I'm a Software Engineer based out of Bangalore, who loves building beautiful products
            and passionate about tech. I graduated from BITS Pilani in 2023 and currently working as
            a Software Engineer in Societe Generale.
          </p>
          <p>
            Ever since I started coding in college, I've been hooked on the excitement of
            turning ideas into reality through programming.
            Whether it's creating user-friendly interfaces or building solid backend systems,
            I love mixing technical know-how with creativity.
            I'm especially interested in making software not just work well,
            but look great too.
          </p>
        </div>
        <div className="skillsTable">
          <div>
            <div className="tableHead">Languages</div>
            <div className="tableItems">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="tableItem">C++</div>
                {/*<span style={{opacity: "40%", fontSize: '0.8rem', margin: "auto 0"}}>Advanced</span>*/}
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="tableItem">JavaScript</div>
                {/*<span style={{opacity: "40%", fontSize: '0.8rem', margin: "auto", marginLeft:'8px'}}>Advanced</span>*/}
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="tableItem">Python</div>
                {/*<span style={{opacity: "40%", fontSize: '0.8rem', margin: "auto 0"}}>Intermediate</span>*/}
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="tableItem">Java</div>
                {/*<span style={{opacity: "40%", fontSize: '0.8rem', margin: "auto 0"}}>Intermediate</span>*/}
              </div>

              {/*<div className="tableItem">JavaScript <span style={{opacity: "40%", fontSize: '0.8rem'}}>[Advanced]</span>*/}
              {/*</div>*/}
              {/*<div className="tableItem">Python <span style={{opacity: "40%", fontSize: '0.8rem'}}>[Intermediate]</span>*/}
              {/*</div>*/}
              {/*<div className="tableItem">Java <span style={{opacity: "40%", fontSize: '0.8rem'}}>[Intermediate]</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div>
            <div className="tableHead">Frameworks</div>
            <div className="tableItems">
              <div className="tableItem">ReactJS</div>
              <div className="tableItem">AngularJS</div>
              <div className="tableItem">Spring Boot</div>
              <div className="tableItem">NodeJS</div>
            </div>
          </div>
          <div>
            <div className="tableHead">Tools</div>
            <div className="tableItems">
              <div className="tableItem">PostgreSQL</div>
              <div className="tableItem">Prisma</div>
              <div className="tableItem">Kubernetes</div>
              <div className="tableItem">Docker</div>
            </div>
          </div>
        </div>
        <div className="connect" style={{ margin: "65px 0 0 0" }}>
          <div>view my resume &nbsp; </div>
          <img src={require("../images/arrow_right.svg").default}></img>
        </div>
      </div>
    </Layout>
  );
};

export default SkillsPage;
