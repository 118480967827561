import React, { useState, useEffect, useRef } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
const { Header, Content, Footer } = Layout;
import { Tabs, Radio } from "antd";
const { TabPane } = Tabs;

import "./experience.scss";

const ExperiencePage = () => {

  return (
    <Layout id="experience" className="layout experience">
      <div className="header">where i've worked.</div>
      <div className="tableexp">
        <div className="desktop_version">
          <Tabs defaultActiveKey="1" tabPosition="left" style={{ height: 400 }}>
            <TabPane tab="Société Générale" key={1}>
              <div className="headingMain">
                <div className="headingRole">Software Developer</div>
                <div className="headingCompany">@ Société Générale</div>
              </div>
              <div className="timeline">July 2023 - Present</div>
              <div className="details">
                <ul>
                  <li>
                    Working on a critical internal compliance tool handling sensitive data,
                    leveraging expertise in <b>AngularJS</b>, <b>Spring Boot</b>, and <b>Postgresql</b>,
                    to deliver visually appealing UI and robust microservices,
                    ensuring regulatory compliance.
                  </li>
                  <li>
                    Enhanced application performance by <b>50%</b> through <b>client-side caching</b> and <b>refactoring legacy components using TypeScript</b>,
                    optimizing load times and improving user experience.
                  </li>
                  <li>
                    Enhanced backend robustness and reliability by adopting <b>Behavior-Driven Development (BDD)</b> practices,
                    deployed with <b>Docker/Kubernetes</b> for flexibility,
                    thereby ensuring the seamless operation of essential endpoints in compliance scenarios.
                  </li>
                </ul>
              </div>
            </TabPane>
            <TabPane tab="Skit.ai" key={2}>
              <div className="headingMain">
                <div className="headingRole">Frontend Engineering Intern</div>
                <div className="headingCompany">@ Skit.ai</div>
              </div>
              <div className="timeline">Nov 2021 - March 2022 & <br></br> July 2022 - December 2022</div>
              <div className="details">
                <ul>
                  <li>
                    Collaborated as a frontend engineer to develop and enhance user interfaces using technologies such as <b>ReactJS</b> with
                    <b> TypeScript</b> and the <b>Ant Design library</b> for elegant component styling.
                  </li>
                  <li>
                    Played a pivotal role in implementing complex features such as an <b>undo-redo functionality</b> across the whole application
                    using <b>Redux</b> for managing the state and <b>Stack</b> Data Structure, improving user interaction across multiple pages.
                  </li>
                  <li>
                    Demonstrated versatility by contributing to <b>Backend in Django (Python)</b>, where I successfully implemented the <b>soft
                    delete feature within data models</b>. Leveraged coding skills to create and integrate various Django models, expanding
                    the application’s functionality.
                  </li>
                </ul>
              </div>
            </TabPane>
            <TabPane tab="Haast" key={3}>
              <div className="headingMain">
                <div className="headingRole">Software Developer Intern</div>
                <div className="headingCompany">@ Haast</div>
              </div>
              <div className="timeline">Jan 2021 - July 2021</div>
              <div className="details">
                <ul>
                  <li>
                    Actively contributed to an <b>Angular</b> project written in <b>TypeScript</b>, took ownership of multiple page sections and
                    successfully implemented an efficient <b>Invoice generation feature</b> from internal data sources. Utilized <b>Material UI</b> to
                    ensure a consistent and visually appealing user interface.
                  </li>
                  <li>
                    Implemented the developer’s app written in <b>AngularJS</b> with
                    backend using <b>Firebase functions</b> from scratch, Which will be
                    used by the admin to control changes. With the help of the
                    features I built, we were able to increase our paid users
                    count from <b>0 to 50+</b> in few months.
                  </li>
                </ul>
              </div>
            </TabPane>
            <TabPane tab="Codeuino" key={4}>
              <div className="headingMain">
                <div className="headingRole">Software Developer Intern</div>
                <div className="headingCompany">@ Codeuino</div>
              </div>
              <div className="timeline">May 2020 - Aug 2020</div>
              <div className="details">
                <ul>
                  <li>
                    Built an <b>open-source project Spansberry</b>, a discussion platform
                    for open source communities. Ideated the project structure
                    with org mentors from the ground up and lead the development.
                  </li>
                  <li>
                    Minimum Viable Product (MVP) was completed within <b>2 months of open-source
                    contributions</b>. This project attracted <b>several new contributors</b> to contribute
                    to Codeuino.
                  </li>
                  <li>
                    Worked as a full-stack developer for the first time and used
                    <b> ReactJS</b> along with <b>TypeScript</b> for UI, <b>NodeJS</b> and <b>Socket.IO</b> for the backend and <b>MongoDB</b> as a database for building the project.
                  </li>
                </ul>
              </div>
            </TabPane>
            {/*<TabPane tab="DVM" key={5}>*/}
            {/*  <div className="headingMain">*/}
            {/*    <div className="headingRole">UI/UX Designer</div>*/}
            {/*    <div className="headingCompany">@ DVM, BITS Pilani</div>*/}
            {/*  </div>*/}
            {/*  <div className="timeline">Sep 2018 - Aug 2020</div>*/}
            {/*  <div className="details">*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        Designed the official Websites, official Android as well as*/}
            {/*        iOS apps, and other minor websites for all the major fests*/}
            {/*        (OASIS, APOGEE, BOSM) of BITS Pilani which were quite crucial*/}
            {/*        for the proper functioning of fests.*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        Lead the Design team in designing the apps and websites which*/}
            {/*        impacted more than 6000+ BITSians as well as 1500+ outsiders*/}
            {/*        who participated in the fests.*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</TabPane>*/}
          </Tabs> </div>
        <div className="mobile_version">
          <div style={{ padding: "20px 0" }}>
            <div className="experienceMain">
              <div className="project_description">
                <div className="headingMain">
                  <div className="headingRole">Software Developer </div>
                  <div className="headingCompany">@ Société Générale</div>
                </div>
                <div className="timeline">July 2023 - Present</div>
                <div className="project_desc_card">
                  <div>
                    <ul>
                      <li>
                        Working on a critical internal compliance tool handling sensitive data,
                        leveraging expertise in <b>AngularJS</b>, <b>Spring Boot</b>, and <b>Postgresql</b>,
                        to deliver visually appealing UI and robust microservices,
                        ensuring regulatory compliance.
                      </li>
                      <li>
                        Enhanced application performance by <b>50%</b> through <b>client-side caching</b> and <b>refactoring
                        legacy components using TypeScript</b>,
                        optimizing load times and improving user experience.
                      </li>
                      <li>
                        Enhanced backend robustness and reliability by adopting <b>Behavior-Driven Development
                        (BDD)</b> practices,
                        deployed with <b>Docker/Kubernetes</b> for flexibility,
                        thereby ensuring the seamless operation of essential endpoints in compliance scenarios.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="project_description">
                <div className="headingMain">
                  <div className="headingRole">Frontend Engineering Intern</div>
                  <div className="headingCompany">@ Skit.ai</div>
                </div>
                <div className="timeline">Nov 2021 - March 2022 & <br></br> July 2022 - December 2022</div>
                <div className="project_desc_card">
                  <div>
                    <ul>
                      <li>
                        Collaborated as a frontend engineer to develop and enhance user interfaces using technologies
                        such as <b>ReactJS</b> with
                        <b> TypeScript</b> and the <b>Ant Design library</b> for elegant component styling.
                      </li>
                      <li>
                        Played a pivotal role in implementing complex features such as an <b>undo-redo
                        functionality</b> across the whole application
                        using <b>Redux</b> for managing the state and <b>Stack</b> Data Structure, improving user
                        interaction across multiple pages.
                      </li>
                      <li>
                        Demonstrated versatility by contributing to <b>Backend in Django (Python)</b>, where I
                        successfully implemented the <b>soft
                        delete feature within data models</b>. Leveraged coding skills to create and integrate various
                        Django models, expanding
                        the application’s functionality.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="project_description">
                <div className="headingMain">
                  <div className="headingRole">Software Developer Intern</div>
                  <div className="headingCompany">@ Haast</div>
                </div>
                <div className="timeline">Jan 2021 - July 2021</div>
                <div className="project_desc_card">
                  <div>
                    <ul>
                      <li>
                        Actively contributed to an <b>Angular</b> project written in <b>TypeScript</b>, took ownership
                        of multiple page sections and
                        successfully implemented an efficient <b>Invoice generation feature</b> from internal data
                        sources. Utilized <b>Material UI</b> to
                        ensure a consistent and visually appealing user interface.
                      </li>
                      <li>
                        Implemented the developer’s app written in <b>AngularJS</b> with
                        backend using <b>Firebase functions</b> from scratch, Which will be
                        used by the admin to control changes. With the help of the
                        features I built, we were able to increase our paid users
                        count from <b>0 to 50+</b> in few months.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="project_description">
                <div className="headingMain">
                  <div className="headingRole">Software Developer Intern</div>
                  <div className="headingCompany">@ Codeuino</div>
                </div>
                <div className="timeline">May 2020 - Aug 2020</div>
                <div className="project_desc_card">
                  <div>
                    <ul>
                      <li>
                        Built an <b>open-source project Spansberry</b>, a discussion platform
                        for open source communities. Ideated the project structure
                        with org mentors from the ground up and lead the development.
                      </li>
                      <li>
                        Minimum Viable Product (MVP) was completed within <b>2 months of open-source
                        contributions</b>. This project attracted <b>several new contributors</b> to contribute
                        to Codeuino.
                      </li>
                      <li>
                        Worked as a full-stack developer for the first time and used
                        <b> ReactJS</b> along with <b>TypeScript</b> for UI, <b>NodeJS</b> and <b>Socket.IO</b> for the
                        backend and <b>MongoDB</b> as a database for building the project.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*<div className="project_description">*/}
              {/*  <div className="headingMain">*/}
              {/*    <div className="headingRole">UI/UX Designer</div>*/}
              {/*    <div className="headingCompany">@ DVM, BITS Pilani</div>*/}
              {/*  </div>*/}
              {/*  <div className="timeline">Sep 2018 - Aug 2020</div>*/}
              {/*  <div className="project_desc_card">*/}
              {/*    <div>*/}
              {/*      <ul>*/}
              {/*        <li>*/}
              {/*          Designed the official Websites, official Android as well as*/}
              {/*          iOS apps, and other minor websites for all the major fests*/}
              {/*          (OASIS, APOGEE, BOSM) of BITS Pilani which were quite crucial*/}
              {/*          for the proper functioning of fests.*/}
              {/*        </li>*/}
              {/*        <li>*/}
              {/*        Lead the Design team in designing the apps and websites which*/}
              {/*          impacted more than 6000+ BITSians as well as 1500+ outsiders*/}
              {/*          who participated in the fests.*/}
              {/*        </li>*/}
              {/*      </ul>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExperiencePage;
