import * as React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import { Tabs, Radio, Tag } from "antd";

const { TabPane } = Tabs;

import "./projects.scss";

const OtherProjects = () => {
  return (
    <Layout className="layout other-projects" style={{ padding: "50px 15%" }}>
      <div className="header">other noteworthy projects.</div>
      <div className="project_header" style={{marginTop:'20px'}}>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Weather App</div>
          </div>
          <div>
            Small Starter NodeJS project which uses API from DarkSky for showing
            the Weather. Users can search the location for which they want to
            see the weather.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">JavaScript (ES6)</Tag>
            <Tag color="default">NodeJS</Tag>
            <Tag color="default">HTML</Tag>
            <Tag color="default">CSS/Sass</Tag>
            <Tag color="default">DarkSky API</Tag>
            <Tag color="default">Heroku</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Arduino API</div>
          </div>
          <div>
            Backend API created using Socket.io for detecting the distance
            between two users and alerting users to follow social distancing via
            Arduino Setup. This project was created with help from one other
            teammate.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">JavaScript (ES6)</Tag>
            <Tag color="default">NodeJS</Tag>
            <Tag color="default">Socket.IO</Tag>
            <Tag color="default">ExpressJS</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Official APOGEE Website</div>
          </div>
          <div>
            Website designed for APOGEE, BITS Pilani's Annual Technical
            Festival. This website was accessed by 1000s of users for
            registrations and publicity. User can see the fest details, events
            and register for the same.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">Adobe XD</Tag>
            <Tag color="default">Adobe Photoshop</Tag>
            <Tag color="default">Adobe Illustrator</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Official OASIS App</div>
          </div>
          <div>
            Android Application Designed for OASIS, BITS Pilani's Annual
            Cultural Fest. App allowed users to track events, order food, keep
            track of Kind Store points as well as Event Signings. This
            application had over 1000+ downloads within a timespan of 4 days.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">Adobe XD</Tag>
            <Tag color="default">Adobe Photoshop</Tag>
            <Tag color="default">Adobe Illustrator</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Official BOSM Website</div>
          </div>
          <div>
            Website designed for BOSM, BITS Pilani's annual Sports Fest. This
            website was accessed by 1000+ users for registrations and publicity.
            User can see the fest details, events and register for the same.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">Adobe XD</Tag>
            <Tag color="default">Adobe Photoshop</Tag>
            <Tag color="default">Adobe Illustrator</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">Secret Confessions</div>
          </div>
          <div>
            A simple app created in Django, in which user can post status
            without loosing anonymity and interact with the post such as Liking
            and Commenting. User can use this app by creating a username which
            can be of their choice
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">Python</Tag>
            <Tag color="default">Django</Tag>
          </div>
        </div>
        <div className="project_desc_card" style={{ marginBottom: "20px" }}>
          <div>
            <div className="project_title">ToDo List App</div>
          </div>
          <div>
            Starter ToDo list app created in Django, Python. Users can use it
            for managing the tasks throughout their day.
          </div>
          <div className="tags" style={{ padding: "16px 0 0" }}>
            <Tag color="default">Python</Tag>
            <Tag color="default">Django</Tag>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OtherProjects;
