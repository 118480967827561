import React, { useEffect, useState } from "react";
import ExperiencePage from "./experience";
import LandingPage from "./landing";
import SkillsPage from "./skills";
import ProjectsPage from "./projects";
import { Layout } from "antd";
import OtherProjects from "./other-projects";
import WhatsNextPage from "./whats-next";
const { Header, Footer } = Layout;
import "./index.scss"

const IndexPage = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const myExtScript = require("../main.js")
    }
  })

  return (
    <Layout>
      <Header
        className="head"
        style={{
          backgroundColor: " hsla(0, 0%, 100%, 0.7)",
          padding: "0px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 6%",
          }}
        >
          <div>
            <div className="name" id="name">
              Devansh Agarwal
            </div>
          </div>
          <div className={`navbar ${isMenuOpen ? 'open' : ''}`}>
            {isMenuOpen ? "" : <div className="menu-toggle" onClick={toggleMenu}>
              ☰
            </div>}
            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
              {isMenuOpen ? <div onClick={toggleMenu} style={{ zIndex: 1000, padding: '12px', marginLeft: 'auto', color: 'black' }}> X </div> : ""}
              <div className="menuItems">
                <a href="#home" className="menuItem flip-text">
                  hom<span style={{ animationDuration: '700ms', animationDelay: '0ms' }}>e</span>
                </a>
                <a href="#about" className="menuItem flip-text">
                  abou<span style={{ animationDuration: '700ms', animationDelay: '0ms' }}>t</span>
                </a>
                <a href="#experience" className="menuItem flip-text">
                    experienc<span style={{ animationDuration: '700ms', animationDelay: '0ms' }}>e</span>
                </a>
                <a href="#projects" className="menuItem flip-text">
                  project<span style={{ animationDuration: '700ms', animationDelay: '0ms' }}>s</span>
                </a>

              </div>
            </div>
          </div>
        </div>
      </Header>
      <LandingPage></LandingPage>
      <SkillsPage></SkillsPage>
      <ExperiencePage></ExperiencePage>
      <ProjectsPage></ProjectsPage>
      <OtherProjects></OtherProjects>
      <WhatsNextPage></WhatsNextPage>
      <Footer style={{ padding: "80px 6% 30px 6%" }}>

        <div className="footer-div" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
          <div className="socialTitlesTop" style={{ display: 'none' }}>
            <div className="socialTitles">
              <a href="mailto:devanshagarwal50@gmail.com" style={{ textDecoration: "none", color: "#4d4d4d" }}>Email</a>
            </div>
            <div>/</div>
            <div className="socialTitles"><a target="_blank" href="https://github.com/devanshcodes" style={{ textDecoration: "none", color: "#4d4d4d" }}>GitHub</a></div>
            <div>/</div>
            <div className="socialTitles"><a target="_blank" href="https://www.linkedin.com/in/devansh-agarwal-739702189/" style={{ textDecoration: "none", color: "#4d4d4d" }}>LinkedIn</a></div>
          </div>

          <div>
            © Devansh Agarwal 2023
          </div>
          {/* <div style={{ display: 'flex', gap: '4rem', fontSize: '16px' }}>
            <div><MailOutlined /> Email</div>
            <div><GithubOutlined /> GitHub</div>
            <div><LinkedinOutlined /> LinkedIn</div>
            
          </div> */}
          <div style={{ display: 'flex' }} className='socialTitlesBottom'>
            <div className="socialTitles">
              <a href="mailto:devanshagarwal50@gmail.com" style={{ textDecoration: "none", color: "#4d4d4d" }}>Email</a>
            </div>
            <div>/</div>
            <div className="socialTitles"><a target="_blank" href="https://github.com/devanshcodes" style={{ textDecoration: "none", color: "#4d4d4d" }}>GitHub</a></div>
            <div>/</div>
            <div className="socialTitles"><a target="_blank" href="https://www.linkedin.com/in/devansh-agarwal-739702189/" style={{ textDecoration: "none", color: "#4d4d4d" }}>LinkedIn</a></div>
          </div>
        </div>
      </Footer>
    </Layout >
  );
};

export default IndexPage;